<template>
  <div class="main" :style="{ position: fixedStatus ? 'fixed' : 'relative' }">
    <div class="bg-area">
      <div class="bg-area-left">
        <el-image
          class="bg-img-bk"
          :src="picture.bkl"
          :style="'left:' + bgPosition.bklPositionLeft"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <el-image
          class="bg-img-ml"
          :src="picture.mll"
          :style="'left:' + bgPosition.mllPositionLeft"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
      <div class="bg-area-right">
        <el-image
          class="bg-img-bk"
          :src="picture.bkr"
          :style="'right:' + bgPosition.bkrPositionRight"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <el-image
          class="bg-img-ml"
          :src="picture.mlr"
          :style="'right:' + bgPosition.mlrPositionRight"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
    </div>
    <div class="content">
      <div class="activity">
        <div class="activity-btn-area">
          <div class="activity-btn-area-item" v-if="audioEmpower">
            <i class="el-icon-microphone"></i>
          </div>
          <div class="activity-btn-area-item" @click="handleShouQuan" v-else>
            <i class="el-icon-turn-off-microphone"></i>
          </div>
          <el-button v-if="fixedStatus" plain @click="handleChangePageSize"
          >退出全屏</el-button>
          <el-button v-if="!fixedStatus" plain @click="handleChangePageSize"
          >进入全屏</el-button>
        </div>
        <div class="activity-name">{{shopName}}&小花豆千城万店 活动</div>
        <div class="activity-duration">
          活动历时：<span class="activity-duration-time">{{activityDuration.hour}}</span> 时 <span class="activity-duration-time">{{activityDuration.minute}}</span> 分 <span class="activity-duration-time">{{activityDuration.second}}</span> 秒
        </div>
        <div class="activity-data-count">
          <div class="activity-data-count-item">
            访问数：<span class="activity-data-count-item-val" id="access_count">0</span>
          </div>
          <div class="activity-data-count-item">
            订单数：<span class="activity-data-count-item-val" id="order_count">0</span>
          </div>
          <div class="activity-data-count-item">
            客户推荐数：<span class="activity-data-count-item-val" id="member_new">0</span>
          </div>
          <div class="activity-data-count-item">
            员工推荐数：<span class="activity-data-count-item-val" id="member_old">0</span>
          </div>
        </div>
        <div class="activity-total-sales" id="order_amount">0</div>
        <div class="activity-carousel" v-if="purchasedList.length">
          <div class="activity-carousel-area">
            <div class="activity-carousel-item" v-for="item in purchasedList" :key="item.id" :style="item.is_read === 1 ? 'margin-left: 0' : 'margin-left: -20%'">
              <div v-if="item.user && item.user.avatar_url">
                <el-image
                  class="activity-carousel-item-avatar"
                  :src="item.user.avatar_url"
                  fit="contain"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>
              <div v-else>
                <el-image
                  class="activity-carousel-item-avatar"
                  src=""
                  fit="contain"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>
              <div class="activity-carousel-item-data">
                <div class="user-name">{{item.user ? item.user.name : '--'}}</div>
                <div class="user-data">{{item.amount}}元</div>
              </div>
            </div>
          </div>
        </div>
        <div class="activity-carousel" v-else>
          <div class="activity-carousel-nodata">暂无数据</div>
        </div>
        <div class="activity-list">
          <div class="activity-list-area" v-if="user.length">
            <div class="activity-list-item-area">
              <div class="activity-list-item" v-for="item in user" :key="item.id">
                <el-image
                  class="activity-list-item-avatar"
                  :src="item.avatar_url"
                  fit="contain"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <el-popover
                  placement="top-start"
                  :title="item.staff.name"
                  width="200"
                  trigger="hover"
                  :content="'订单：' + item.order_count + '，金额：' + item.order_amount"
                >
                  <div class="activity-list-item-data" slot="reference">
                    <div class="user-name">{{item.staff.name}}</div>
                    <div class="user-data">
                      <div class="user-data-item">
                        订单：<span class="user-data-item-val">{{item.order_count}}</span>
                      </div>
                      <div class="user-data-item">
                        金额：<span class="user-data-item-val">{{item.order_amount}}</span>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
          <div class="activity-list-area" v-else>
            <div class="activity-list-item-area-nodata">暂无数据</div>
          </div>
        </div>
      </div>
    </div>
    <audio
      :src="audioUrl"
      ref="audio"
    >Your browser does not support the audio element.</audio>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shopName: '', // 店铺名称
      fixedStatus: false, // 全屏标识
      audioEmpower: false, // 是否授权播放音频播报
      isShouQuan: false, // 是否已经授权
      picture: { // 背景图片路径
        bkl: require("@/assets/bkl.png"),
        bkr: require("@/assets/bkr.png"),
        mll: require("@/assets/mll.png"),
        mlr: require("@/assets/mlr.png"),
      },
      bgPosition: {
        mllPositionLeft: '-25vh',
        mlrPositionRight: '-25vh',
        bklPositionLeft: '-42vh',
        bkrPositionRight: '-42vh',
      },
      nums: {
        order_amount: 0,
        access_count: 0,
        order_count: 0,
        member_new: 0,
        member_old: 0,
      },
      start_time: '', // 活动开始时间
      activityBegin: false, // 活动是否已经开始
      activityDuration: {
        hour: '00',
        minute: '00',
        second: '00',
      },
      audioUrl: require("@/assets/music/订单来了.mp3"), // 音频路径
      audio: null, // 音频对象
      user: [], // 员工列表
      purchasedList: [], // 购买用户 列表
      lastPurChasedList: [], // 上次请求获取的 购买用户 列表
      titlename: [], // 员工名称
      datas: [], // 订单金额占比
      valdata: [], // 订单金额
      timer: null, // 初始化定时器
      timers: [], // 定时器列表(保存定时器)
      timerList: [], // 定时器列表(保存定时器)
      activityDurationTimer: null, // 初始化活动历时计时器
      orderAmountFontSize: '315px', // 成交金额字号（根据长度进行调整）
      sayData: [],
      sayIndex: 0,
    }
  },
  methods: {
    /**
     * 授权播报
     */
    handleShouQuan() {
      this.audioEmpower = true;
      this.isShouQuan = true;
      this.$refs.audio.muted = true;
      this.$refs.audio.play();
    },
    /**
     * 切换全屏
     */
    handleChangePageSize() {
      this.bgPosition = {
        mllPositionLeft: '-25vh',
        mlrPositionRight: '-25vh',
        bklPositionLeft: '-42vh',
        bkrPositionRight: '-42vh',
      }
      setTimeout(() => {
        this.bgPosition = {
          mllPositionLeft: '0',
          mlrPositionRight: '0',
          bklPositionLeft: '0',
          bkrPositionRight: '0',
        }
        this.fixedStatus = !this.fixedStatus;
      }, 1000)
    },
    /**
     * 数字初次渲染特效
     */
    variabledLoadingNum(obj, index) {
      const that = this;
      const timerListLen = this.timerList.length;
      if (index < timerListLen) {
        if (obj.value != this.timerList[index].value) {
          this.timerList[index].value = obj.value;
          document.querySelector(obj.dom).innerHTML = obj.value;
        }
        return false;
      } else {
        that.timerList[index] = obj;
        if (obj.value > 0) {
          that.timerList[index].step = obj.value / 20; // 步进值
          that.timerList[index].count = 0; // 计数器
          that.timerList[index].text = document.querySelector(obj.dom); // dom
          that.timerList[index].timer = setInterval(() => {
            that.timerList[index].count = that.timerList[index].count + that.timerList[index].step;
            if (that.timerList[index].count >= that.timerList[index].value) {
              that.timerList[index].count = that.timerList[index].value;
              clearInterval(that.timerList[index].timer);
            }
            that.timerList[index].text.innerHTML = Number(that.timerList[index].count.toFixed(2));
          }, 40);
        }
      }
    },
    /**
     * 获取数据
     */
    getList(params) {
      const parameter = params;
      this.$axios({
        href: "/api/app/meiye/wechat_order/index",
        loading: true,
      }).then((res) => {
        if (res && res.data && res.data.successful === 1) {
          this.user = res.data.data && res.data.data.users ? res.data.data.users : [];
          if (this.nums.access_count < res.data.data.access_count) {
            this.nums.access_count = res.data.data.access_count;
            this.variabledLoadingNum({value: res.data.data.access_count, dom: "#access_count"}, 0);
          }
          if (this.nums.order_count < res.data.data.order_count) {
            console.log('parameter', parameter);
            this.variabledLoadingNum({value: res.data.data.order_count, dom: "#order_count"}, 1);
            // 语音播报开始
            if (parameter) {
              const sayDataPushLen = res.data.data.order_count - this.nums.order_count;
              this.nums.order_count = res.data.data.order_count;
              if (this.isShouQuan && sayDataPushLen) {
                // 订单语音播报
                for (let j = 0; j < sayDataPushLen; j++) {
                  this.sayData.push({status: false})
                }
                this.sayNum();
              }
            } else {
              this.nums.order_count = res.data.data.order_count;
            }
            // 语音播报结束
          }
          if (this.nums.member_new < res.data.data.member_new) {
            this.nums.member_new = res.data.data.member_new;
            this.variabledLoadingNum({value: res.data.data.member_new, dom: "#member_new"}, 2);
          }
          if (this.nums.member_old < res.data.data.member_old) {
            this.nums.member_old = res.data.data.member_old;
            this.variabledLoadingNum({value: res.data.data.member_old, dom: "#member_old"}, 3);
          }
          if (this.nums.order_amount < res.data.data.order_amount) {
            this.nums.order_amount = res.data.data.order_amount;
            this.variabledLoadingNum({value: res.data.data.order_amount, dom: "#order_amount"}, 4);
          }
          if (this.start_time != res.data.data.start_time) {
            this.start_time = res.data.data.start_time;
            const timeStamp = new Date();
            const startTimeStamp = new Date(res.data.data.start_time);
            if (timeStamp > startTimeStamp) {
              this.activityTiming();
            }
          }
          let orderArr = res.data.data && res.data.data.order ? res.data.data.order : [];
          const orderArrLen = orderArr.length;
          if (orderArr && orderArrLen) {
            if (JSON.stringify(orderArr) != JSON.stringify(this.lastPurChasedList)) {
              this.lastPurChasedList = orderArr;
              this.purchasedList = orderArr;
              setTimeout(() => {
                this.purchasedList.forEach(item => {
                  item.is_read = 1;
                })
              }, 500)
            }
          }
        }
      });
    },
    /**
     * 语音播报
     */
    sayNum() {
      const that = this;
      const sayData = that.sayData;
      const sayDataLen = sayData.length;
      const sayDataLastIndex = sayData.length - 1;
      const index = that.sayIndex;
      let num = 0;
      let timer = null;
      for (let i = index; i < sayDataLen; i++) {
        if (sayData[i] && !sayData[i].status) {
          sayData[i].status = true;
          timer = setTimeout(() => {
            this.$refs.audio.muted = false;
            this.$refs.audio.play();
          }, (2500 * num));
          that.timers.push(timer);
          if (i === sayDataLastIndex) {
            that.sayIndex = sayDataLen;
          } else {
            num = num + 1;
          }
        }
      }
    },
    /**
     * 活动计时器
     */
    activityTiming() {
      const that = this;
      const start_time = that.start_time;
      const timeStamp = new Date().getTime();
      const startTimeStamp = new Date(start_time).getTime();
      let timeStampDiff = Math.floor((timeStamp - startTimeStamp) / 1000); // 将时间差转换成秒
      clearInterval(that.activityDurationTimer);
      that.activityDurationTimer = setInterval(function() {
        if (that.activityBegin) {
          const nextSecond = Number(that.activityDuration.second) + 1;
          if (nextSecond > 59) {
            that.activityDuration.second = '00';
            const nextMinute = Number(that.activityDuration.minute) + 1;
            if (nextMinute > 59) {
              that.activityDuration.minute = '00';
              const nextHour = Number(that.activityDuration.hour) + 1;
              that.activityDuration.hour = nextHour > 9 ? nextHour : '0' + nextHour;
            } else {
              that.activityDuration.minute = nextMinute > 9 ? nextMinute : '0' + nextMinute;
            }
          } else {
            const nextSecond = Number(that.activityDuration.second) + 1;
            that.activityDuration.second = nextSecond > 9 ? nextSecond : '0' + nextSecond;
          }
        } else {
          timeStampDiff = timeStampDiff + 1; // 时间差每秒 + 1
          that.activityBegin = !that.activityBegin;
          const hour = Math.floor(timeStampDiff / (60 * 60));
          const minute = Math.floor((timeStampDiff - (hour * 60 * 60)) / 60);
          const second = Math.floor(timeStampDiff - (hour * 60 * 60) - (minute * 60));
          that.activityDuration.hour = hour > 9 ? hour : '0' + hour;
          that.activityDuration.minute = minute > 9 ? minute : '0' + minute;
          that.activityDuration.second = second > 9 ? second : '0' + second;
        }
      }, 1000);
    },
  },
  created() {
    const that = this;
    clearInterval(that.timer);
    that.getList(false);
    that.timer = setInterval(() => {
      that.getList(true);
    }, 2000);
    const userData = JSON.parse(sessionStorage.getItem("init"));
    this.shopName = userData.shop.name;
  },
  mounted() {
    setTimeout(() => {
      this.bgPosition = {
        mllPositionLeft: '0',
        mlrPositionRight: '0',
        bklPositionLeft: '0',
        bkrPositionRight: '0',
      }
    }, 200)
  },
  destroyed() {
    this.sayStatus = false;
    // 清除定时器
    clearInterval(this.timer);
    clearInterval(this.activityDurationTimer);
    const len = this.timers.length;
    const lastIndex = len - 1;
    for (let i = 0; i < len; i++) {
      clearTimeout(this.timers[i]);
      if (i === lastIndex) {
        this.timers = [];
        this.sayData = [];
      }
    }
    console.log("离开页面");
  }
}
</script>

<style lang="scss" scoped>
  .main {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    background-color: #A00707;
    .bg-area {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100vh;
      &-left {
        position: relative;
        overflow: hidden;
        width: 30%;
        height: 100%;
        .bg-img-ml {
          position: absolute;
          top: 0;
          left: -25vh;
          width: 25vh;
          height: 100vh;
          transition: linear left 1s;
        }
        .bg-img-bk {
          position: absolute;
          top: 0;
          left: -42vh;
          width: 42vh;
          height: 100vh;
          transition: linear left 1s;
        }
      }
      &-right {
        position: relative;
        overflow: hidden;
        width: 30%;
        height: 100%;
        .bg-img-ml {
          position: absolute;
          top: 0;
          right: -25vh;
          width: 25vh;
          height: 100vh;
          transition: linear right 1s;
        }
        .bg-img-bk {
          position: absolute;
          top: 0;
          right: -42vh;
          width: 42vh;
          height: 100vh;
          transition: linear right 1s;
        }
      }
    }
    .content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      min-width: 116.3vh;
      .activity {
        width: 100%;
        height: 100%;
        padding: 12.04vh 6.94vh 0 6.94vh;
        box-sizing: border-box;
        &-btn-area {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          height: 12.04vh;
          padding: 0 30px;
          box-sizing: border-box;
          &-item {
            margin-right: 15px;
            font-size: 28px;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
        &-name {
          font-size: 4.44vh;
          font-weight: bold;
          color: #FFFFFF;
          text-align: center;
        }
        &-duration {
          margin: 1.11vh auto 3.7vh;
          font-size: 2.4vh;
          font-weight: bold;
          font-style: italic;
          color: #FFFFFF;
          text-align: center;
          &-time {
            color: #FCFF00;
          }
        }
        &-data-count {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 90vh;
          height: 6.29vh;
          margin: 0 auto;
          padding: 0 2.5vh;
          background-color: #B3180E;
          border-radius: 3.15vh;
          box-sizing: border-box;
          &-item {
            display: flex;
            align-items: center;
            font-size: 1.48vh;
            color: #FFFFFF;
            &-val {
              font-size: 3.33vh;
            }
          }
        }
        &-total-sales {
          height: 29.17vh;
          font-size: 26.11vh;
          font-family: "Microsoft YaHei";
          font-weight: bold;
          color: #FCFF00;
          line-height: 29.17vh;
          text-align: center;
        }
        &-carousel {
          overflow: hidden;
          width: 90vh;
          height: 6.3vh;
          margin: 1.39vh auto 0;
          padding: 0 3.33vh;
          background-color: #B3180E;
          border-radius: 3.15vh;
          box-sizing: border-box;
          &-area {
            overflow: hidden;
            width: 100%;
            height: 6.3vh;
            padding-top: 1.3vh;
            .activity-carousel-item {
              float: left;
              display: flex;
              align-items: center;
              overflow: hidden;
              width: 18%;
              height: 3.7vh;
              margin-right: 2%;
              margin-bottom: 1.3vh;
              transition: ease margin-left .5s;
              &-avatar {
                overflow: hidden;
                width: 3.7vh;
                height: 3.7vh;
                border-radius: 50%;
                vertical-align: bottom;
                /deep/ .image-slot {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 3.7vh;
                  height: 3.7vh;
                  background-color: #CCCCCC;
                  font-size: 2.22vh;
                  color: #FFFFFF;
                  border-radius: 50%;
                }
              }
              &-data {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                overflow: hidden;
                height: 3.7vh;
                margin-left: 0.93vh;
                text-overflow: ellipsis;
                white-space: nowrap;
                .user-name {
                  overflow: hidden;
                  font-size: 1.3vh;
                  line-height: 1.3vh;
                  color: #FFFFFF;
                  text-align: left;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                .user-data {
                  overflow: hidden;
                  font-size: 1.3vh;
                  line-height: 1.3vh;
                  color: #FCFF00;
                  text-align: left;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
          &-nodata {
            font-size: 1.67vh;
            text-align: center;
            color: #FFFFFF;
            line-height: 6.3vh;
          }
        }
        &-list {
          position: relative;
          overflow: hidden;
          width: 116.3vh;
          height: 16.67vh;
          margin: 4.63vh auto 0;
          &-area {
            position: absolute;
            top: 0;
            right: -17px;
            bottom: 0;
            left: 0;
            overflow-y: scroll;
            .activity-list-item-area {
              display: flex;
              flex-wrap: wrap;
              &-nodata {
                width: 116.3vh;
                height: 16.67vh;
                font-size: 2.4vh;
                line-height: 16.67vh;
                text-align: center;
                color: #FFFFFF;
              }
              .activity-list-item {
                display: flex;
                align-items: center;
                width: 25.46vh;
                height: 6.76vh;
                margin-right: 4.6vh;
                margin-bottom: 3.14vh;
                padding: 0.93vh;
                background-color: #B33939;
                box-sizing: border-box;
                border-radius: 3.38vh;
                &-avatar {
                  overflow: hidden;
                  width: 4.91vh;
                  height: 4.91vh;
                  border-radius: 50%;
                  /deep/ .image-slot {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 4.91vh;
                    height: 4.91vh;
                    background-color: #CCCCCC;
                    font-size: 2.78vh;
                    color: #FFFFFF;
                    border-radius: 50%;
                  }
                }
                &-data {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  width: 17.3vh;
                  height: 4.91vh;
                  margin-left: 1.39vh;
                  .user-name {
                    font-size: 1.48vh;
                    text-align: left;
                    color: #FFFFFF;
                  }
                  .user-data {
                    display: flex;
                    flex-wrap: nowrap;
                    &-item {
                      overflow: hidden;
                      margin-right: 1.39vh;
                      font-size: 1.11vh;
                      color: #FFFFFF;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      &-val {
                        color: #FCFF00;
                      }
                    }
                    &-item:last-child {
                      margin: 0;
                    }
                  }
                }
              }
              .activity-list-item:hover {
                cursor: pointer;
              }
              .activity-list-item:nth-child(4n) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-height: 810px) {
    .main {
      height: 810px;
      .bg-area {
        height: 810px;
        &-left {
          .bg-img-ml {
            left: -203px;
            width: 203px;
            height: 810px;
          }
          .bg-img-bk {
            left: -340px;
            width: 340px;
            height: 810px;
          }
        }
        &-right {
          .bg-img-ml {
            right: -203px;
            width: 203px;
            height: 810px;
          }
          .bg-img-bk {
            right: -340px;
            width: 340px;
            height: 810px;
          }
        }
      }
      .content {
        min-width: 942px;
        min-height: 810px;
        .activity {
          padding: 98px 56px 0 56px;
          &-btn-area {
            height: 98px;
          }
          &-name {
            font-size: 36px;
          }
          &-duration {
            margin: 9px auto 30px;
            font-size: 19px;
          }
          &-data-count {
            width: 729px;
            height: 50px;
            padding: 0 20px;
            border-radius: 25px;
            &-item {
              font-size: 12px;
              &-val {
                font-size: 27px;
              }
            }
          }
          &-total-sales {
            height: 236px;
            font-size: 211px;
            line-height: 236px;
          }
          &-carousel {
            width: 730px;
            height: 50px;
            margin: 11px auto 0;
            padding: 0 27px;
            border-radius: 25px;
            &-area {
              height: 50px;
              padding-top: 10px;
              .activity-carousel-item {
                height: 30px;
                margin-bottom: 10px;
                &-avatar {
                  width: 30px;
                  height: 30px;
                  /deep/ .image-slot {
                    width: 30px;
                    height: 30px;
                    font-size: 18px;
                  }
                }
                &-data {
                  height: 30px;
                  margin-left: 8px;
                  .user-name {
                    font-size: 10px;
                  }
                  .user-data {
                    font-size: 10px;
                  }
                }
              }
            }
            &-nodata {
              font-size: 14px;
              line-height: 50px;
            }
          }
          &-list {
            width: 942px;
            height: 135px;
            margin: 37px auto 0;
            &-area {
              .activity-list-item-area {
                &-nodata {
                  width: 942px;
                  height: 135px;
                  font-size: 19px;
                  line-height: 135px;
                }
                .activity-list-item {
                  width: 206px;
                  height: 56px;
                  margin-right: 37px;
                  margin-bottom: 26px;
                  padding: 7px;
                  border-radius: 28px;
                  &-avatar {
                    width: 40px;
                    height: 40px;
                    /deep/ .image-solt {
                      width: 40px;
                      height: 40px;
                      font-size: 22px;
                    }
                  }
                  &-data {
                    width: 140px;
                    height: 40px;
                    margin-left: 11px;
                    .user-name {
                      font-size: 12px;
                    }
                    .user-data-item {
                      margin-right: 11px;
                      font-size: 9px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
